import React, { useState, useEffect, useCallback } from 'react';
import { Carousel } from '@mantine/carousel';
import { MantineProvider } from '@mantine/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import footprint from "../src/footprint.png";
import Logo from "../src/Logo_white.png";
import "../src/index.css";
import Modal from 'react-bootstrap/Modal';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import './App.css';


function App() {
  const [show, setShow] = useState(true);
  const [showone, setShowone] = useState(false);

  const [ShowTwo, setShowTwo] = useState(false);
  const [ShowThree, setShowThree] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseone = () => setShowone(false);

  const handleCloseTwo = () => setShowTwo(false);
  const handleCloseThree = () => setShowThree(false);
  const [indexx, setindex] = useState(0)
  const [counter, setCounter] = React.useState(70);


  React.useEffect(() => {
    if (parseInt(localStorage.getItem('infolist')) == 1) {
      console.log('djdhjdhdhdhghdhdhd')
      setShow(false)
      localStorage.removeItem('infolist')
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  console.log('sjshs')
  console.log(counter)
  console.log('sjshs')

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <section className='headerBlueBx'>
        <Container>
          <Row>
            <Col sm={12}>
              <div className='asa'>
                <img src={Logo} alt='logo' />
              </div>
            </Col>
            <Col sm={12} className="px-0">
              <div className='mainBx'>
                <span>
                  <img src={footprint} alt='footprint' />
                  <h2>A step towards sustainability</h2>
                </span>
                <div className='contentBx'>
                  <p>Grow indigo is happy to bring you this delicious <br></br> sustainable rice.</p>
                  <p>The rice in your hand contributed to the environment's <br></br> emission of fewer greenhouse gases. </p>
                  <p>We are grateful to our farmers for switching to sustainability <br></br> and climate-conscious farming practices.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='sliderContainer'>
        <Container>
          <Row>
            {
              indexx == 0 ?
                <Col>
                  <div className='mainBx2'>
                    <h2 style={{ color: '#007333' }}>Traceability</h2>
                    <p style={{ color: '#111111' }}>We track the journey of low-carbon produce to ensure incentives for farmers to adopt sustainable practices.</p>
                  </div>
                </Col>
                :
                indexx == 1 ?
                  <Col>
                    <div className='mainBx2'>
                      <h2 style={{ color: '#059bac' }}>Sustainable Growers</h2>
                      <p style={{ color: '#111111' }}>Not all heroes wear capes. Some like our farmers took pertinent to build a sustainable world for generations after them.</p>
                    </div>
                  </Col>
                  : indexx == 2 ?
                    <Col>
                      <div className='mainBx2'>
                        <h2 style={{ color: '#f18200' }}>Rice- Climate Solution</h2>
                        <p style={{ color: '#111111' }}>Our rice helps in reducing GHG gas emissions in the environment. <br></br>
                          Good for you, great for the environment.</p>
                      </div>
                    </Col>
                    :
                    <Col>
                      <div className='mainBx2'>
                        <h2 style={{ color: '#f18200' }}></h2>
                        <p style={{ color: '#111111' }}></p>
                      </div>
                    </Col>
            }
          </Row>
          <Row>
            <Carousel className='slideBox' slideSize="85%" loop slideGap="xs" controlsOffset="xl" withControls={false} withIndicators
              onSlideChange={(e) => setindex(e)}>
              <Carousel.Slide className='asa asa2 position-relative'>
                {
                  show ?
                    <Modal id="myModal" show={counter == 1 ? false : show} onHide={handleClose} className="cusModalStyle" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                      <Modal.Body className="p-0">
                        <div id="player-youtube-1"></div>
                      </Modal.Body>

                      <Modal.Header className='customCloseBTN' closeButton>
                      </Modal.Header>
                    </Modal>
                    :
                    null
                }
                <Modal show={showone} onHide={handleCloseone} className="cusModalStyle" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Body className="p-0">
                    <iframe className='sss ssspopup' src="https://www.youtube.com/embed/clcsUhqoZu8?autoplay=1&mute=1&loop=1&rel=0&showinfo=0" allow="autoplay" controls="0" rel="0" frameborder="0" allowfullscreen="1"></iframe>
                  </Modal.Body>
                </Modal>
                <div className='blankBx' variant="primary" onClick={() => setShowone(true)}></div>
                <LiteYouTubeEmbed
                  id="clcsUhqoZu8"
                />
              </Carousel.Slide>

              <Carousel.Slide className='asa asa2 position-relative'>
                <Modal show={ShowTwo} onHide={handleCloseTwo} className="cusModalStyle" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Body className="p-0">
                    <iframe className='sss ssspopup' src="https://www.youtube.com/embed/WP5XmY2u14k?autoplay=1&mute=1&loop=1&rel=0&showinfo=0" allow="autoplay" controls="0" rel="0" frameborder="0" allowfullscreen="1"></iframe>
                  </Modal.Body>
                </Modal>
                <div className='blankBx' variant="primary" onClick={() => setShowTwo(true)}></div>
                <LiteYouTubeEmbed
                  id="WP5XmY2u14k"
                />
              </Carousel.Slide>

              <Carousel.Slide className='asa asa2 position-relative'>
                <Modal show={ShowThree} onHide={handleCloseThree} className="cusModalStyle" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                  <Modal.Body className="p-0">
                    <iframe className='sss ssspopup' src="https://www.youtube.com/embed/hKjUuw0tigY?autoplay=1&mute=1&loop=1&rel=0&showinfo=0" allow="autoplay" controls="0" rel="0" frameborder="0" allowfullscreen="1"></iframe>
                  </Modal.Body>
                </Modal>
                <div className='blankBx' variant="primary" onClick={() => setShowThree(true)}></div>
                <LiteYouTubeEmbed id="hKjUuw0tigY" />
              </Carousel.Slide>
            </Carousel>
          </Row>
        </Container>
      </section>
    </MantineProvider>
  );
}

export default App;
